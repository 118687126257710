<template>
    <div>
        <aw-tabler :table="table">
            <template #filter>
                <el-form ref="formRef" :model="table.filter" :inline="true">
                    <el-form-item label="订单编号/商品名称:">
                        <el-input v-model="table.filter.trade" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="收货人/手机号:">
                        <el-input v-model="table.filter.consignee" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="用户ID/手机号:">
                        <el-input v-model="table.filter.uid" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="下单时间:">
                        <aw-date-picker
                            v-model="table.filter.time"
                            :SelectTimesCustom="back_SelectTimesCustom"
                            :Date_Disabled="true"
                            :Button_Change="{ chat: '请选择', type: 'daterange' }"
                            :Out_Time="{ time_size: 8035200, erro_message: '选中的时间差不能超过 93 天' }"
                            @change-data="changeDataSearchEvent"
                        />
                    </el-form-item>
                </el-form>
            </template>
            <template #table_tab>
                <el-tabs v-model="table.filter.status" @tab-change="handlerTabChangeEvent">
                    <el-tab-pane label="全部" name=""></el-tab-pane>
                    <el-tab-pane label="待付款" name="-1"></el-tab-pane>
                    <el-tab-pane label="交易完成" name="1"></el-tab-pane>
                    <el-tab-pane label="交易取消" name="2"></el-tab-pane>
                </el-tabs>
            </template>
            <template #drawercontent>
                <div v-if="table.drawer.flex == 'remark'">
                    <OrderRemark
                        :modelInfo="table.drawer.form"
                        @close-drawer="
                            () => {
                                table.drawer.show = false
                                table.page.currentChange(1)
                            }
                        "
                    />
                </div>
                <div v-else-if="table.drawer.flex == 'order_info'">
                    <OrderInfo :modelInfo="table.drawer.form" />
                </div>
            </template>
        </aw-tabler>
    </div>
</template>
<script setup lang="jsx">
import { http, util, dict, tool } from '@/assets/js'
import { reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import { tableColumn } from '@/assets/js/extend/tableColumn'
import { ElMessage } from 'element-plus'
import OrderRemark from './OrderRemark.vue'
import OrderInfo from './OrderInfo.vue'

// 选中时间
const back_SelectTimesCustom = ref('')
const changeDataSearchEvent = (data) => {
    back_SelectTimesCustom.value = [data[0] * 1000, data[1] * 1000]
    table.filter.time = data[0] + ' ~ ' + data[1]
}

const route = useRoute()
// 当前页面操作权限集
const powerlimits = reactive(tool.getArrayToOneChat(route.meta.role, 'component'))

const table = reactive({
    title: '用户列表',
    select: false,
    loading: false,
    filter: {
        trade: '',
        consignee: '',
        uid: '',
        status: '',
        time: ''
    },
    datas: [],
    columns: [
        ...tableColumn([
            {
                title: '订单编号',
                key: 'trade_no',
                minWidth: '100px'
            },
            {
                title: '商品名称',
                key: 'oitem_info',
                minWidth: '100px',
                render: (data) => {
                    return <span>{data.oitem.goods_name}</span>
                }
            },
            {
                title: '用户信息',
                key: 'user_info',
                minWidth: '100px',
                render: (data) => {
                    return (
                        <span>
                            用户ID:{data.user.id + '\n'}用户昵称:{data.user.nickname + '\n'}手机号:{data.user.mobile}
                        </span>
                    )
                }
            },
            {
                title: '代理商分佣',
                key: 'agent_info',
                minWidth: '100px',
                render: (data) => {
                    return (
                        <span>
                            代理商:{data.agent.name + '\n'}手机号:{data.agent.username + '\n'}分佣金额:{(parseInt(data.agent_price) / 100).toFixed(2)}
                        </span>
                    )
                }
            },
            {
                title: '认证信息',
                key: 'audit_user_info',
                minWidth: '100px',
                render: (data) => {
                    if (data.audit_user_info != null) {
                        return (
                            <span>
                                法人姓名:{data.audit_user_info.realname + '\n'}联系手机号:{data.audit_user_info.phone + '\n'}所在地区:{data.audit_user_info.address}
                            </span>
                        )
                    } else {
                        return
                    }
                }
            },
            {
                title: '收货人信息',
                key: 'get_goods_info',
                minWidth: '100px',
                render: (data) => {
                    return (
                        <span>
                            收货人:{data.in_name + '\n'}手机号:{data.in_mobile + '\n'}所在地区:{data.in_areacode_name + '\n'}详细地址:{data.in_address}
                        </span>
                    )
                }
            },
            {
                title: '件数',
                key: 'oitem.buy_num',
                minWidth: '100px',
                render: (data) => {
                    return <span>{data.oitem.buy_num}</span>
                }
            },
            {
                title: '实付金额',
                key: 'pay_price',
                minWidth: '100px',
                render: (data) => {
                    return <span>{(parseInt(data.pay_price) / 100).toFixed(2)}</span>
                }
            },
            { title: '下单时间', key: 'addtime', minWidth: '100px', time: true },
            {
                title: '订单状态',
                key: 'status',
                minWidth: '100px',
                tag: (data) => {
                    return [{ text: dict.order.order_status[data.status], props: { type: dict.order.order_status_type[data.status] } }]
                }
            },
            {
                title: '代理商备注',
                key: 'agent_remark',
                minWidth: '100px',
                render: (data) => {
                    return (
                        <el-popover
                            placement={'top'}
                            width={'200'}
                            trigger={'hover'}
                            content={data.agent_remark}
                            v-slots={{ reference: () => <span class={'no-warp-5 c-p'}>{data.agent_remark}</span> }}
                        ></el-popover>
                    )
                }
            },
            {
                title: '备注',
                key: 'admin_remark',
                minWidth: '100px',
                render: (data) => {
                    return (
                        <el-popover
                            placement={'top'}
                            width={'200'}
                            trigger={'hover'}
                            content={data.admin_remark}
                            v-slots={{ reference: () => <span class={'no-warp-5 c-p'}>{data.admin_remark}</span> }}
                        ></el-popover>
                    )
                }
            }
        ]),
        {
            fixed: 'right',
            title: '操作',
            key: 'operate',
            minWidth: '160px',
            buttons: [
                {
                    name: '备注',
                    props: { type: 'primary', size: 'small' },
                    show: () => powerlimits.includes('ddxx_bz'),
                    action: (data) => {
                        table.drawer.show = true
                        table.drawer.title = '备注'

                        table.drawer.flex = 'remark'
                        table.drawer.form = {
                            id: data.id,
                            remark: data.remark
                        }
                    }
                },
                {
                    name: '订单详情',
                    props: { type: 'primary', size: 'small' },
                    show: () => powerlimits.includes('ddxx_ddxq'),
                    action: async (data) => {
                        table.drawer.show = true
                        table.drawer.title = '订单详情'

                        let payload = await http.get(`admin/order/${data.id}`)
                        table.drawer.flex = 'order_info'
                        table.drawer.form = [
                            {
                                title: '订单详情',
                                content: [
                                    { title: '订单状态：', chat: dict.order.order_status[payload.status], tag: { props: { type: dict.order.order_status_type[payload.status] } } },
                                    { title: '订单编号：', chat: payload.trade_no },
                                    { title: '下单时间：', chat: payload.addtime * 1000, time: true }
                                ]
                            },
                            {
                                title: '用户信息',
                                content: [
                                    { title: '用户ID：', chat: payload.user.id },
                                    { title: '用户昵称：', chat: payload.user.nickname },
                                    { title: '手机号：', chat: payload.user.mobile }
                                ]
                            },
                            {
                                title: '认证信息',
                                content: [
                                    { title: '法人姓名：', chat: payload.audit_user_info.realname },
                                    { title: '联系人手机号：', chat: payload.audit_user_info.phone },
                                    { title: '所在地区：', chat: payload.audit_user_info.address }
                                ]
                            },
                            {
                                title: '收货人信息',
                                content: [
                                    { title: '收货人：', chat: payload.in_name },
                                    { title: '手机号：', chat: payload.in_mobile },
                                    { title: '所在地区：', chat: payload.in_areacode_name },
                                    { title: '详细地址：', chat: payload.in_address }
                                ]
                            },
                            {
                                title: '商品信息',
                                props: {
                                    table: true,
                                    mate: { image: 'img' },
                                    title: { image: '图片', name: '名称', price: '单价', number: '数量', all_price: '商品总价' }
                                },
                                content: [
                                    {
                                        image: payload.oitem.goods_imgs?.split(',')[0],
                                        name: payload.oitem.goods_name,
                                        price: (parseInt(payload.oitem.unit_price) / 100).toFixed(2),
                                        number: parseInt(payload.oitem.buy_num),
                                        all_price: (parseInt(payload.oitem.total_price) / 100).toFixed(2)
                                        // true_money: (parseInt(payload.oitem.pay_price) / 100).toFixed(2)
                                    }
                                ]
                            }
                        ]

                        // 特殊处理
                        if (payload.status != -1) {
                            table.drawer.form[0].content.push({
                                title: payload.status == 1 ? '成交时间：' : '交易取消时间：',
                                chat: payload.final_time * 1000,
                                time: true
                            })
                        }
                    }
                }
            ]
        }
    ],
    page: {
        total: 0,
        page: 1,
        page_size: 20,
        sizeChange: (val) => {
            table.page.page_size = val
            table.action(table.filter)
        },
        currentChange: (val) => {
            table.page.page = val
            table.action(table.filter)
        }
    },
    action: async (params) => {
        if (powerlimits.includes('ddxx_lb')) {
            if (!params) params = { status: -1 }
            table.loading = true
            let page = { page: table.page.page, page_size: table.page.page_size }
            let result = await http.get('/admin/order', { params: { ...params, ...route.query, ...page } })
            table.datas = result.data
            table.page.total = result.total || Infinity
            table.page.page = result.current_page
            table.page.page_size = result.per_page
            table.loading = false
        } else {
            table.datas = []
            table.page.total = Infinity
        }
        return
    },
    extras: [
        {
            name: '导出',
            props: { type: 'warning', size: 'small' },
            show: () => powerlimits.includes('ddxx_dc'),
            action: async () => {
                let http_url = route.meta.role.find((n) => n.component == 'ddxx_dc')['route']
                await http.get(http_url, { params: { ...table.filter, ...route.query }, sMsg: true })
            }
        }
    ],
    drawer: reactive({
        flex: null,
        form: {},
        show: false,
        title: '',
        close: () => {
            table.drawer.show = false
        }
    })
})

// 切换tab
const handlerTabChangeEvent = async (status) => {
    table.action({ ...table.filter, status: status })
}

handlerTabChangeEvent(table.filter.status)
</script>
<style lang="scss" scoped>
.box-item {
    width: 200px;
}
</style>
